import { useState, useEffect, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useSwipeable } from "react-swipeable";
import { Icon } from "@iconify/react";

const ImageGallery = ({ images }) => {
  const [isOpenimage, setIsOpenimage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 4;
  const totalPages = images?.length ? Math.ceil(images.length / imagesPerPage) : 1;

  // ✅ Ensure hooks run before any conditional return
  const prevImage = useCallback(() => {
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  }, [images?.length]);

  const nextImage = useCallback(() => {
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  }, [images?.length]);

  const handlers = useSwipeable({
    onSwipedLeft: nextImage,
    onSwipedRight: prevImage,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") prevImage();
      if (event.key === "ArrowRight") nextImage();
      if (event.key === "Escape") closeModalimage();
    };

    if (isOpenimage) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenimage, prevImage, nextImage]);

  // ✅ Now we check for empty images AFTER hooks are declared
  if (!images || images.length === 0) return null;

  const openModalimage = (index) => {
    setCurrentIndex(index);
    setIsOpenimage(true);
  };

  const closeModalimage = () => setIsOpenimage(false);

  const paginatedImages = images.slice((currentPage - 1) * imagesPerPage, currentPage * imagesPerPage);

  return (
    <div>
      <ul className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {paginatedImages.map((image, index) => (
          <li key={index} className="relative group cursor-pointer">
            <img
              src={image.url}
              alt={`Image ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform transform group-hover:scale-105"
              onClick={() => openModalimage(index)}
            />
            <div className="absolute bottom-2 text-white right-2 bg-black bg-opacity-50 p-1 rounded-md">
              <a href={image.url} download={`${image.contentId}.jpg`}>
                <Icon icon="line-md:cloud-alt-download-loop" width="38" height="38" />
              </a>
            </div>
          </li>
        ))}
      </ul>

      {/* Simplified Pagination Controls */}
      <div className="flex justify-center gap-2 text-xs font-medium mt-4">
        <button 
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} 
          className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900"
          disabled={currentPage === 1}
        >
          <ChevronLeft size={16} />
        </button>
        <span className="size-8 flex items-center justify-center border border-gray-100 bg-white text-gray-900 rounded">
          {currentPage} / {totalPages}
        </span>
        <button 
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} 
          className="inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900"
          disabled={currentPage === totalPages}
        >
          <ChevronRight size={16} />
        </button>
      </div>

      {isOpenimage && (
        <Dialog open={isOpenimage} onClose={closeModalimage} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="relative w-full max-w-4xl mx-auto p-4" {...handlers}>
            <div className="relative flex items-center justify-center">
              <button
                onClick={closeModalimage}
                className="absolute top-4 right-4 text-white hover:text-red-500 bg-black bg-opacity-50 p-1 rounded-md"
              >
                <Icon icon="fluent-mdl2:hide-3" width="24" height="24" />
              </button>
              <button className="absolute left-4 text-white" onClick={prevImage}>
                <ChevronLeft size={48} />
              </button>
              <img
                src={images[currentIndex].url}
                alt={`I-${currentIndex + 1}`}
                className="w-full max-h-[80vh] object-contain rounded-lg"
              />
              <button className="absolute right-4 text-white" onClick={nextImage}>
                <ChevronRight size={48} />
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default ImageGallery;
