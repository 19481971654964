import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Icon} from "@iconify/react";
import axiosInstance, { URL } from "../utils/axios";
const PropertyModel = ({ data, onClose,propertyUrl }) => {
  const [propertyImage, setPropertyImage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };
  // const handleNavigate = () => {
  //   // I want to extract ID from an array of an object

  //   // const id = data[0].id;
  //   // navigate(`/propertyDetails/${id}`);
  //   window.location.href = propertyUrl
  // };
  useEffect(() => {
    const handleImageProperty = async () => {
      await axiosInstance
        .get(`/api/images/${data[0].id}`)
        .then((response) => {
          setPropertyImage(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    handleImageProperty();
  }, [data]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % propertyImage?.length;
      return newIndex;
    });
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        prevIndex === 0 ? propertyImage?.length - 1 : prevIndex - 1;
      return newIndex;
    });
  };
  const showImage = (index) => {
    setCurrentIndex(index);
  };
  console.log(propertyImage);
  return (
    <div className="fixed z-[999] inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-full max-w-2xl rounded p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Property Details</h2>
          <button
            className="text-red-600 font-bold text-lg "
            onClick={handleClose}
          >
            ✕
          </button>
        </div>
        <div className="max-h-[90vh] overflow-y-auto">
          <div className="flex flex-col sm:flex-row mb-4">
            <div className="relative w-full aspect-w-16 aspect-h-9 bg-gray-200">
              {propertyImage?.length > 0 ? (
                <>
                <img
                    src={`${propertyImage[currentIndex].image_url}`}
                    alt={ `Property Image_${currentIndex}`}
                    className="w-full h-[25rem] object-cover"
                  />
                  <div className="flex items-center justify-between absolute top-1/2 w-full px-5">
                  <button
                    onClick={() =>
                      showImage(
                        currentIndex - 1 < 0
                          ? propertyImage?.length - 1
                          : currentIndex - 1
                      )
                    }
                    className="px-3 py-1 bg-red-600 bg-opacity-20 hover:bg-red-600 text-white transition ease-in-out"
                  >
                    <Icon icon="ep:arrow-left-bold" width="12" height="24" />
                  </button>
                  <button
                    onClick={() =>
                      showImage(
                        currentIndex + 1 === propertyImage?.length
                          ? 0
                          : currentIndex + 1
                      )
                    }
                    className="px-3 py-1 bg-red-600 bg-opacity-20 hover:bg-red-600 text-white transition ease-in-out"
                  >
                    <Icon icon="weui:arrow-filled" width="12" height="24" />
                  </button>
                </div>
                </>
                  
              ) : (
                <p className="text-center">Loading image...</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {data.map(
              (detail) =>
                detail.value !== null &&
                detail.value !== undefined &&
                detail.value !== "" && (
                  <div key={detail.label} className="border p-2 rounded">
                    <div className="font-bold text-gray-700">
                      {detail.label}:
                    </div>
                    <div className="text-gray-900 break-words">
                      {detail.value}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="text-center mt-4">
            <a
              className="bg-red-500 text-white px-2 py-1 rounded"
              // onClick={() => handleNavigate()}
              href={propertyUrl}
            >
              View More Details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyModel;
