import { createContext, useContext } from "react";
import axiosInstance from "../utils/axios";
import { toast } from "react-hot-toast";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const login = async (email, password) => {
    try {
      const res = await axiosInstance.post(`/api/auth/login`, {
        email,
        password,
      });

      localStorage.setItem("token", res.data.token);
      toast.success(res.data.message);
    } catch (error) {
      toast.error("Login failed");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider
      value={{ 
        login, 
        logout, 
        isAuthenticated: !!localStorage.getItem("token") // Directly check localStorage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
