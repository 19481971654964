import React, { useEffect, useState } from "react";
import CustomTable from "./table";
import PropertyModel from "./PropertyMadel";
import { URL } from "../utils/axios";
const Table = () => {
  const [properties, setProperties] = useState([]); // Ensure properties starts as an array
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });


  useEffect(() => {
    fetch(`${URL}/api/properties`) // Backend API endpoint
      .then((response) => response.json())
      .then((data) => {
        // Ensure data is an array before proceeding
        let arr = Array.isArray(data) ? data : [data];
        setProperties(arr);
      })
      .catch((error) => console.error("Error fetching properties:", error));
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.toLowerCase());
  };

  const handleFilterChange = (e) => {
    console.log("filter", e);
    setFilterStatus(e);
  };

  const filteredProperties = properties.filter((property) => {
    const matchesSearch =
      searchQuery === "" ||
      Object.values(property).some((value) =>
        String(value).toLowerCase().includes(searchQuery)
      );
    const matchesFilter =
      filterStatus === "" || property.status === filterStatus;
    return matchesSearch && matchesFilter;
  });

  const openDetailModal = (property) => {
    setSelectedProperty(property);
    setShowModal(true);
  };

  const closeDetailModal = () => {
    setShowModal(false);
    setSelectedProperty(null);
  };



  const renderTable = (status, isRent = false) => {
    const statusFiltered = filteredProperties.filter(
      (property) => property.status === status
    );
    const columns = [
      {
        title: "BUILD. #",
        dataIndex: "building_number",
        key: "building_number",
        type: "number",
      },
      {
        title: "UNIT #",
        dataIndex: "unit_number",
        key: "unit_number",
        type: "number",
      },
      {
        title: "BE",
        dataIndex: "bedrooms",
        key: "bedrooms",
        type: "number",
      },
      {
        title: "BA",
        dataIndex: "bathrooms",
        key: "bathrooms",
        type: "number",
      },
      {
        title: "SF",
        dataIndex: "square_feet",
        key: "square_feet",
        type: "number",
      },
      {
        title: "VIEW",
        dataIndex: "view",
        key: "view",
        type: "string",
      },
      {
        title: "UNIT TYPE",
        dataIndex: "unit_type",
        key: "unit_type",
        type: "string",
      },
      ...(isRent
        ? [{ title: 'LEASE TYPE', key: 'lease_type', dataIndex: 'lease_type', type: 'string' }]
        : []),
      {
        title: isRent ? 'RENT AMOUNT' : 'PRICE',
        key: 'price_or_rent',
        dataIndex: 'price_or_rent',
        type: "number",
      },
    ]
    return (
      <div className="mb-12 relative">
        <h3 className="bg-red-600 text-white font-bold text-lg p-2">
          {status.toUpperCase()} IN YARCOBR
        </h3>
           <CustomTable columns={columns} dataSource={statusFiltered} onClickRow={(property) => openDetailModal(property)}/>
      </div>
    );
  };

  const renderDetailModal = () => {
    if (!selectedProperty) return null;
    function parseJsonFields(obj) {
      const parsedObj = {};

      for (const key in obj) {
        if (
          typeof obj[key] === "string" &&
          obj[key].startsWith("[") &&
          obj[key].endsWith("]")
        ) {
          try {
            parsedObj[key] = JSON.parse(obj[key]); // Convert JSON string to an array
          } catch (error) {
            console.error(`Error parsing ${key}:`, error);
            parsedObj[key] = obj[key]; // Keep the original value if parsing fails
          }
        } else {
          parsedObj[key] = obj[key]; // Keep normal values as they are
        }
      }

      return parsedObj;
    }

    const formatArrayField = (value) => {
      if (Array.isArray(value)) return value.join(", "); // Convert array to a string with spaces
      if (typeof value === "string" && value.startsWith("[") && value.endsWith("]")) {
        try {
          return JSON.parse(value).join(", "); // Parse and format if it's a JSON string
        } catch {
          return value; // Return original if parsing fails
        }
      }
      return value;
    };

    const parsedData = parseJsonFields(selectedProperty); // Ensure JSON-like strings are parsed

    const details = [
      { label: "ID", id: formatArrayField(parsedData.id) },
      { label: "Building Number", value: formatArrayField(parsedData.building_number) },
      { label: "Unit Number", value: formatArrayField(parsedData.unit_numbe) },
      { label: "Bedrooms", value: formatArrayField(parsedData.bedrooms) },
      { label: "Bathrooms", value: formatArrayField(parsedData.bathrooms) },
      { label: "Square Feet", value: formatArrayField(parsedData.square_feet) },
      { label: "View", value: formatArrayField(parsedData.view) },
      { label: "Unit Type", value: formatArrayField(parsedData.unit_type) },
      { label: "Status", value: formatArrayField(parsedData.status) },
      { label: "Price / Rent", value: formatArrayField(parsedData?.price_or_rent) === null ? formatArrayField(parsedData?.price_or_rent) : "N/A" },
      { label: "Lease Type", value: formatArrayField(parsedData.lease_type) },
      { label: "Coordinates", value: formatArrayField(parsedData.coordinates) },
      { label: "Street Number", value: formatArrayField(parsedData.street_number) },
      { label: "Compass Direction", value: formatArrayField(parsedData.compass_direction) },
      { label: "Street Name", value: formatArrayField(parsedData.street_name) },
      {
        label: "Post Street Direction",
        value: formatArrayField(parsedData.post_street_direction),
      },
      { label: "City", value: formatArrayField(parsedData.city) },
      { label: "State", value: formatArrayField(parsedData.state) },
      { label: "Zip Code", value: formatArrayField(parsedData.zip_code) },
      { label: "Zip Plus Four", value: formatArrayField(parsedData.zip_plus_four) },
      { label: "County", value: formatArrayField(parsedData.county) },
      { label: "Real Estate Taxes", value: formatArrayField(parsedData.real_estate_taxes) },
      {
        label: "Real Estate Tax Year",
        value: formatArrayField(parsedData.real_estate_tax_year),
      },
      { label: "Front Door Facing", value: formatArrayField(parsedData.front_door_facing) },
      { label: "Home Type", value: formatArrayField(parsedData.home_type) },
      { label: "Lot Size", value: formatArrayField(parsedData.lot_size) },
      {
        label: "Garage Parking Type",
        value: formatArrayField(parsedData.garage_parking_type),
      },
      { label: "Garage Size", value: formatArrayField(parsedData.garage_size) },
      {
        label: "Driveway Parking Spaces",
        value: formatArrayField(parsedData.driveway_parking_spaces),
      },
      {
        label: "Total Square Footage",
        value: formatArrayField(parsedData.total_square_footage),
      },
      { label: "Year Built", value: formatArrayField(parsedData.year_built) },
      {
        label: "Stories Inside Home",
        value: formatArrayField(parsedData.stories_inside_home),
      },
      {
        label: "Master Bedroom Floor",
        value: formatArrayField(parsedData.master_bedroom_floor),
      },
      { label: "Pool Type", value: formatArrayField(parsedData.pool_type) },
      { label: "Outdoor Sitting", value: formatArrayField(parsedData.outdoor_sitting) },
      { label: "Private Amenities", value: formatArrayField(parsedData.private_amenities) },
      { label: "Renovated", value: formatArrayField(parsedData.renovated) },
      { label: "Renovation Date", value: formatArrayField(parsedData.renovation_date) },
      { label: "Waterfront", value: formatArrayField(parsedData.waterfront) },
      { label: "Waterfront Type", value: formatArrayField(parsedData.waterfront_type) },
      {
        label: "Waterfront Frontage",
        value: formatArrayField(parsedData.waterfront_frontage),
      },
      { label: "Boat Access", value: formatArrayField(parsedData.boat_access) },
      { label: "Dock Type", value: formatArrayField(parsedData.dock_type) },
      { label: "View Type", value: formatArrayField(parsedData.view_type) },
      { label: "Renovation Type", value: formatArrayField(parsedData.renovation_type) },
      { label: "Floor Type", value: formatArrayField(parsedData.floor_type) },
      { label: "Roof Type", value: formatArrayField(parsedData.roof_type) },
      { label: "Roof Age", value: formatArrayField(parsedData.roof_age) },
      {
        label: "Air Conditioning Type",
        value: formatArrayField(parsedData.air_conditioning_type),
      },
      { label: "Construction Type", value: formatArrayField(parsedData.construction_type) },
      { label: "Additional Rooms", value: formatArrayField(parsedData.additional_rooms) },
      { label: "Interior Features", value: formatArrayField(parsedData.interior_features) },
      {
        label: "Home Exterior Features",
        value: formatArrayField(parsedData.home_exterior_features),
      },
      { label: "Appliances", value: formatArrayField(parsedData.appliances) },
    ];

    return (
      <>
      <PropertyModel data={details} onClose={()=>closeDetailModal()} propertyUrl={formatArrayField(parsedData.property_details_url)}/>
      </>
    );
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold text-center text-red-600 mb-2">
        YACHT AND RACQUET CLUB OF BOCA RATON{" "}
      </h2>
      <p className="text-center text-gray-700 mb-6">
        A luxury resort-style community with private marina and private beach
      </p>

      {renderTable("Sale")}
      {renderTable("Rent", true)}
      {renderTable("Sold")}
      {renderTable("Rented", true)}


      {showModal && selectedProperty && renderDetailModal()}
    </div>
  );
};

export default Table;
