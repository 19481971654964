import React, { useState, useEffect } from "react";
import { IoFilter, IoReloadOutline } from "react-icons/io5";
import { Icon } from "@iconify-icon/react";
const CustomTable = ({ columns, dataSource, onClickRow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [activeColumn, setActiveColumn] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [value, setValue] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [previousColumn, setPreviousColumn] = useState(null);

  const displayedData =
    filteredData.length > 0
      ? filteredData
      : sortedData.length > 0
      ? sortedData
      : dataSource;

  useEffect(() => {
    setFilteredData([]);
    setSortedData(dataSource);
  }, [dataSource]);

  const handleSort = (key) => {
    console.log(`Sorting by key: ${key}`);

    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";

    const sortedArray = [...displayedData].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      // Handle both string and number sorting
      if (typeof aValue === "string" && typeof bValue === "string") {
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      return 0; // Fallback for mixed types or missing values
    });

    setSortedData(sortedArray);
    setSortConfig({ key, direction });
  };

  const buttonData = [
    // Numeric filters
    {
      label: "Greater than or equal",
      filter: (columnKey, value) => (item) =>
        Number(item[columnKey]) >= Number(value),
    },
    {
      label: "Less than or equal",
      filter: (columnKey, value) => (item) =>
        Number(item[columnKey]) <= Number(value),
    },
    {
      label: "Equal",
      filter: (columnKey, value) => (item) =>
        Number(item[columnKey]) === Number(value),
    },
    {
      label: "Between",
      filter: (columnKey, value) => (item) => {
        const [min, max] = value.split(",").map(Number);
        return Number(item[columnKey]) >= min && Number(item[columnKey]) <= max;
      },
    },
    // string filter
    {
      label: "Contains",
      filter: (columnKey, value) => (item) =>
        item[columnKey].toLowerCase().includes(value.toLowerCase()),
    },
  ];

  const renderFilterButtons = (columnKey, columnType) => {
    if (columnType === "number") {
      return buttonData
        .filter((button) => button.label !== "Contains")
        .map((button, index) => (
          <button
            key={index}
            className={`px-2 py-1 whitespace-nowrap ${
              activeButton === index ? "bg-gray-200" : "hover:bg-gray-100"
            }`}
            onClick={() => setActiveButton(index)}
          >
            {button.label}
          </button>
        ));
    } else if (columnType === "string") {
      return buttonData.map((button, index) => {
        if (button.label === "Contains") {
          return (
            <button
              key={index}
              className={`px-2 py-1 whitespace-nowrap ${
                activeButton === index ? "bg-gray-200" : "hover:bg-gray-100"
              }`}
              onClick={() => setActiveButton(index)} // Use original index
            >
              {button.label}
            </button>
          );
        }
        return null;
      });
    }
    return null;
  };

  const handleApply = () => {
    if (activeColumn && activeButton !== null && value.trim() !== "") {
      const columnKey = activeColumn;
      const filterFunction = buttonData[activeButton].filter(columnKey, value);

      const newFilteredData = dataSource.filter(filterFunction);

      setFilteredData(newFilteredData);
      setPreviousColumn(columnKey); // Save the previous column for reset

      // Reset states
      setActiveColumn(null);
      setActiveButton(null);
      setValue("");
    }
  };

  const handleReset = (columnKey) => {
    if (sortConfig.key === columnKey) {
      setSortConfig({ key: null, direction: null }); // Clear sorting for this column
    }
    if (previousColumn === columnKey) {
      setFilteredData([]); // Reset filtered data
      setActiveColumn(null); // Clear active filter for this column
      setValue(""); // Clear active filter value for this column
      setPreviousColumn(null); // Clear previous column
    }
  };

  return (
    <div className="overflow-y-auto max-h-[500px] shadow-md">
      <table className="w-full text-sm text-left border-collapse border border-gray-300 text-gray-500 rtl:text-right max-h-[500px]">
      <thead className="sticky top-0 z-10 bg-gray-50">
          <tr className="bg-green-100">
            {columns &&
              columns.map((column) => (
                <th
                  key={column.key}
                  className="px-3 py-3 font-medium text-gray-900 border border-gray-300"
                >
                  <div className="flex gap-2 items-center">
                    <p>{column.title}</p>
                    <div className="flex justify-center items-center gap-1">
                      <Icon
                        icon={
                          sortConfig.key === column.key
                            ? sortConfig.direction === "asc"
                              ? "memory:arrow-up-bold"
                              : "memory:arrow-down-bold"
                            : "memory:sort" // Default sort icon instead of always "down"
                        }
                        width="11"
                        height="11"
                        className="p-2 rounded-full hover:bg-gray-200 cursor-pointer transition-transform ease-in-out delay-75"
                        onClick={() => handleSort(column.key)}
                      />
                      <p
                        className="w-6 h-6 rounded-full hover:bg-gray-200 flex items-center justify-center"
                        onClick={() =>
                          setActiveColumn((prev) =>
                            prev === column.key ? null : column.key
                          )
                        }
                      >
                        <IoFilter />
                      </p>
                      {(sortConfig.key === column.key ||
                        previousColumn === column.key) && (
                        <p
                          className="w-6 h-6 rounded-full hover:bg-gray-200 flex items-center justify-center"
                          onClick={() => handleReset(column.key)}
                        >
                          <IoReloadOutline /> {/* Use any reset/reload icon */}
                        </p>
                      )}
                      {activeColumn === column.key && (
                        <div className="absolute z-[9999] top-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg p-4">
                          {activeButton === null ? (
                            <div className="flex flex-col gap-4">
                              {renderFilterButtons(column.key, column.type)}
                            </div>
                          ) : (
                            <div className="flex flex-col gap-4">
                              <p className="text-sm font-medium text-gray-700">
                                {buttonData[activeButton].label}
                              </p>
                              <input
                                type="text"
                                placeholder={`${
                                  activeButton === 3
                                    ? "Enter min,max"
                                    : "Enter value"
                                }`}
                                className="p-2 border-b border-red-500 focus:outline-none"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleApply();
                                  }
                                }}
                              />
                              <div className="flex justify-between">
                                <button
                                  className="px-4 py-2 text-sm text-gray-500 rounded-md hover:bg-gray-300"
                                  onClick={() => {
                                    setActiveButton(null);
                                    setValue("");
                                    setActiveColumn(null);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className={`px-4 py-2 text-sm ${
                                    value.trim() === ""
                                      ? "text-gray-200 cursor-not-allowed"
                                      : "text-red-500 hover:bg-red-200"
                                  } rounded-md`}
                                  onClick={handleApply}
                                  disabled={value.trim() === ""}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {displayedData.map((row, index) => (
            <tr
              key={row.id || index}
              className="bg-white border-b cursor-pointer hover:bg-gray-100"
              onClick={() => onClickRow(row)}
            >
              {columns.map((column) => (
                <td
                key={column.key}
                className="px-6 py-4 whitespace-nowrap border border-gray-300"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event from bubbling if needed
                  onClickRow(row);
                }}
              >              
                  <div className="text-sm text-gray-900">
                    {column.dataIndex === "price_or_rent"
                      ? `${
                          row[column.dataIndex] !== null
                            ? `$ ${row[column.dataIndex]?.toLocaleString()}`
                            : "N/A"
                        }`
                      : row[column.dataIndex]}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
